import { NavLink } from 'react-router-dom';
import styled from 'styled-components'

export const FooterContainer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #ff0000;
    margin-top: -0.5rem;
    padding: 0.5rem;
    padding-bottom: 0.5rem;
`

export const InfoContainer = styled.div`
    max-width: 500px;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 0.5rem;
`

export const DatosContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
`

export const IconsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const IconLink = styled.a`
    color: #ff0000;
    font-size: 24px;
    padding-right: 1rem;
`

export const InfoP = styled.p`
    font-size: 12px;
`

export const BlueSub = styled.span`
    font-weight: bold;
	color: #0000CD;
`

export const HomeRoute = styled(NavLink)`
    font-size: 12px;
    text-decoration: none;
    text-align: center;
    color: #ff0000;
    font-weight: bold;
`
