const ordenReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD_DISH':
            return (
                [
                    ...state,
                    {
                        title: action.payload.title,
                        price: action.payload.price,
                        desc: action.payload.desc,
                        count: 1
                    }
                ]
            );
        case 'MORE_DISH':
            return (
                state.map(item => item.title === action.payload.title
                    ?
                        {
                            title: action.payload.title,
                            price: action.payload.price,
                            desc: action.payload.desc,
                            count: action.payload.count
                        }
                    :
                        item
                )
            );
        case 'LESS_DISH':
            return (
                state.map(item => item.title === action.payload.title
                    ?
                        {
                            title: action.payload.title,
                            price: action.payload.price,
                            desc: action.payload.desc,
                            count: action.payload.count - 1
                        }
                    :
                        item
                )
            );
        case 'REMOVE_DISH':
            return (
                state.filter(item => item.title !== action.payload.title)
            );
        case 'CLEAR':
            return [];
        default:
            return state;
    };
};

export default ordenReducer;
