import styled from 'styled-components';

export const DishContainer = styled.div`
    padding: 6px 0px;
`

    //font-family: 'Caveat', cursive;
export const Dish = styled.p`
    font-size: 15px;
`

export const PriceContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Price = styled.p`
    font-size: 15px;
`

export const LessButton = styled.button`
    font-size: 10px;
    font-weight: bold;
    color: white;
    padding: 0px 15px;
    border: none;
    border-radius: 4px;
    background-color: #f44336;
    cursor: pointer;
`

export const BoldSpan = styled.span`
    font-weight: bold;
`

export const HLine = styled.hr`
    margin-top: 8px;
    border-top: 2px solid #ff0000;
    color: transparent;
`
