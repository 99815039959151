import {
    Button
} from './style';
import { clearOrder } from '../../actions';
import { connect } from 'react-redux';
import React from 'react';

const ClearButton = ({ clearOrder }) => {
    return (
        <>
            <Button
                onClick={() => clearOrder()}
            >
                Limpiar orden
            </Button>
        </>
    );
};

export default connect(null, { clearOrder })(ClearButton);
