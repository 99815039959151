import {
    FlexContainer,
    AppContainer,
} from './style';
import Navbar from './navbar';
import Footer from './footer';
import Paellas from '../pages/menuPersonal';
import CharolasBotaneras from '../pages/charolasBotaneras';
import Home from '../pages/home';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import React from 'react';

const App = () => {
    return (
        <Router>
            <Switch>
                <FlexContainer>
                    <AppContainer>
                        <Navbar />
                            <Route exact path='/eventos' component={CharolasBotaneras} />
                            <Route exact path='/menu_express' component={Paellas} />
                            <Route exact path='/' component={Home} />
                        <Footer />
                    </AppContainer>
                </FlexContainer>
            </Switch>
        </Router>
    );
};

export default App;
