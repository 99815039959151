import {
    DishContainer,
    Dish,
    PriceContainer,
    Price,
    LessButton,
    BoldSpan,
    HLine
} from './style';
import { lessDish, removeDish } from '../../actions';
import { connect } from 'react-redux';
import React from 'react';

const OrderItem = ({ title, desc, price, count, removeDish, lessDish }) => {

    const handleLessDish = (title, price, count) => {
        count === 1
            ? removeDish(title)
            : lessDish(title, desc, price, count);
    };

    return (
        <DishContainer>
            <Dish>
                {title} <BoldSpan>x{count}</BoldSpan>
            </Dish>
            <PriceContainer>
                <Price>${price * count}</Price>
                <LessButton
                    onClick={
                        () => handleLessDish(title, price, count)
                    }
                >
                    -
                </LessButton>
            </PriceContainer>
            <HLine />
        </DishContainer>
    );
};

export default connect(null, { removeDish, lessDish })(OrderItem);
