export const addDish = (title, desc, price) => {
    return { type: 'ADD_DISH', payload: { title, desc, price }};
};

export const moreDish = (title, desc, price, count) => {
    return { type: 'MORE_DISH', payload: { title, desc, price, count }};
};

export const lessDish = (title, desc, price, count) => {
    return { type: 'LESS_DISH', payload: { title, desc, price, count }};
};

export const removeDish = title => {
    return { type: 'REMOVE_DISH', payload: { title }};
};

export const clearOrder = () => {
    return { type: 'CLEAR' };
};

export const nameChange = name => {
    return { type: 'NAME_CHANGE', payload: { name }};
};

export const timeChange = time => {
    return { type: 'TIME_CHANGE', payload: { time }};
};
