import styled from 'styled-components';

export const ImgContainer = styled.div`
    position: relative;
    height: 50vh;
    margin: 3rem 0;
    margin-bottom: 3.5rem;
    background-image: url(${props => props.background});
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;

    @media screen and (min-width: 550px) {
        height: 80vh;
    }
`

export const Heading = styled.h2`
    position: absolute;
    bottom: 0;
    right: 1rem;
    font-family: 'Caveat', cursive;
    font-size: 50px;
    font-weight: 600;
    color: #FFF533;
    padding: 1rem;
`

export const Logo = styled.img`
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    height: 60px;

    @media screen and (min-width: 550px) {
        height: 100px;
    }
`

