import styled from 'styled-components';

export const DishesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: .5rem;
    padding-bottom: 0.5rem;
`

export const ExpandIcon = styled.div`
    display: inline;
    padding-left: 0.5rem;
    font-size: 1.2rem;
`

export const Heading = styled.h2`
    font-family: 'Caveat', cursive;
    font-weight: 600;
    color: ${props => props.color};
    cursor: pointer;
`

export const PlatoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;

    @media screen and (min-width: 600px) {
        grid-template-columns: 220px 220px;
    }
   
    @media screen and (min-width: 820px) {
        grid-template-columns: 220px 220px 220px;
    }
`
