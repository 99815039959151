const inputReducer = (state = { name: '', time: '13:00' }, action) => {
    switch (action.type) {
        case 'NAME_CHANGE':
            return { ...state, name: action.payload.name };
        case 'TIME_CHANGE':
            return { ...state, time: action.payload.time };
        default:
            return state;
    };
};

export default inputReducer;
