import {
    InputField,
    InputLabel,
} from './style';
import { nameChange, timeChange } from '../../actions';
import { connect } from 'react-redux';
import React from 'react';

const DataInput = ({ nameChange, timeChange, inputData }) => {

    return (
        <div>
            <form onSubmit={e => e.preventDefault()}>
                <div>
                    <InputLabel>A nombre de:</InputLabel>
                    <InputField
                        type="text"
                        value={inputData.name}
                        onChange={e => nameChange(e.target.value)}
                    />
                </div>
                <div style={{ marginTop: '.5rem' }}>
                    <InputLabel>Recoger hoy a las:</InputLabel>
                    <InputField
                        type="time"
                        min="13:00"
                        max="20:00"
                        value={inputData.time}
                        onChange={e => timeChange(e.target.value)}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return ({ inputData: state.inputData });
};

export default connect(mapStateToProps, { nameChange, timeChange })(DataInput);
