import {
    Card,
    Desc,
    PriceContainer,
    Price,
    AddButton,
    DishImg,
    QtyContainer,
    Qty
} from './style';
import { addDish, moreDish } from '../../actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import LazyLoad from 'react-lazy-load';
import React from 'react';

const Plato = ({ dishList, addDish, moreDish, order, showButton, color}) => {

    const handleAddition = (title, desc, price) => {
        if (_.find(order, { title: title })) {
            let count = _.find(order, { title: title }).count + 1;
            moreDish(title, desc, price, count);
        } else {
            addDish(title, desc, price);
        }
    };

    const renderDishes = () => {
        return dishList.map(dish => 
            <Card key={dish.title} color={color}>
                { dish.qty
                    ?
                        <QtyContainer color={color}>
                            <Qty>{dish.qty}</Qty>
                        </QtyContainer>
                    :
                        null
                }
                <LazyLoad >
                      <DishImg src={dish.image} alt={dish.title}/>
                </LazyLoad>
                <Price top={dish.top} right={dish.right}> ${dish.price} </Price>
            {//<Titulo>{dish.title}</Titulo>
            }
                <Desc>{dish.desc}</Desc>
                <PriceContainer>
                    ${dish.price}
                    { showButton
                        ?
                            <AddButton onClick={() => handleAddition(dish.title, dish.desc, dish.price)}>
                                +
                            </AddButton>
                        :
                            null
                    }
                </PriceContainer>
            </Card>
        );
    };

    return (
        <>
            {renderDishes()}
        </>
    );
};

const mapStateToProps = state => {
    return ({ order: state.order });
};

export default connect(mapStateToProps, { addDish, moreDish })(Plato);
