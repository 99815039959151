import {
    ImgContainer,
    Heading,
    Logo
} from './style';
import React from 'react';

const Home = () => {
    const showcaseFondo = 'images/showcases/home.jpg';
    const logo = 'images/logos/lasPaellasLogo.png';
    return (
        <>
            <ImgContainer
                background={showcaseFondo}
            >
                <Logo
                    src={logo}
                />
                <Heading>
                    ¡Bienvenidos!
                </Heading>
            </ImgContainer>
        </>
    );
};

export default Home;
