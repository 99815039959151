import {
    SendButton,
    DisabledButton
} from './style';
import { connect } from 'react-redux';
import React from 'react';

const WaButton = ({ order, inputData }) => {

    const tel = '8180623247';

    let orderInfo = `Nombre:\n\t${inputData.name}\nHora:\n\t${inputData.time}\n`
    let dishList = "Pedido: \n";
    order.forEach(item => {
            dishList = dishList + '\t' + item.count + ' ' + item.title + '\n';
    });
    const message = orderInfo + dishList;
    const encodedMessage = encodeURIComponent(message);

    const isOpen = () => {
        var date = new Date();
        var day = date.getDay();
        var hour = date.getHours();
        if (hour < 9) {
            return false;
        } else if (day === 0 && hour >= 17) {
            return false;
        } else if (hour >= 22) {
            return false;
        } else {
            return true;
        }
    }
    const open = isOpen();

    if (open) {
        return (
            <SendButton
                href={`https://wa.me/521${tel}/?text=${encodedMessage}`}
                target="_blank"
            >
                Enviar orden
            </SendButton>
        );
    } else {
        return (
            <DisabledButton>
                Cerrado
            </DisabledButton>
        );
    }
};

const mapStateToProps = state => {
    return ({ order: state.order, inputData: state.inputData });
};

export default connect(mapStateToProps, {})(WaButton);
