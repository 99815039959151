import styled from 'styled-components';

export const Button = styled.button`
    height: 2rem;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    border: transparent;
    background-color: #ff0000;
    color: white;
`
