import {
    InfoFlex,
    InfoGrid,
    MessageH1,
    MessageH3,
} from './style';
import React from 'react';

const Showcase = ({ m1, m3, fondo}) => {
    return (
        <InfoFlex background={fondo}>
            <InfoGrid>
                <MessageH1>
                    {m1}
                </MessageH1>
                <MessageH3>
                    {m3}
                </MessageH3>
            </InfoGrid>
        </InfoFlex>
    );
};

export default Showcase;
