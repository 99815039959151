import styled from 'styled-components';

export const InputLabel = styled.label`
    font-size: 14px;
`

export const InputField = styled.input`
    width: 100%;
    height: 2rem;
    border-radius: 4px;
    border-color: #03396c;
    padding: 0 5px;
    box-sizing: border-box; /* add this */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
`
