import styled from 'styled-components';

export const InfoFlex = styled.div`
    display: flex;
    height: 30vh;
    justify-content: center;
    padding: 0.5rem;
    padding-top: 0;
    border-bottom: 2px solid #ff0000;
    background-image: url(${props => props.background});
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
`

export const InfoGrid = styled.div`
    position: relative;
    padding: 2.5rem 1rem;
`

export const MessageH1 = styled.h1`
    font-family: 'Caveat', cursive;
    font-weight: 700;
    font-size: 46px;
    color: #FFF533;
    padding: 1rem 0;
    text-align: center; 
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
`

export const MessageH3 = styled.h3`
    font-weight: 700;
    color: white;
    font-size: 22px;
    text-align: center; 
    max-width: 300px;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
`

export const TextBox = styled.div`
    font-family: 'Caveat', cursive;
    font-size: 20px;
    font-weight: bold;
    max-width: 300px;
    text-align: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 4px;
    border: 2px #ff0000 solid;
    background-color: white;
`

