import {
    GridContainer,
    LeftContainer,
    HLine,
    RightContainer,
    OrderContainer,
    YellowSub
} from './style';
import Showcase from '../../components/showcase';
import Platillos from '../../components/platillos';
import Order from '../../components/orden';
import Total from '../../components/total';
import ClearButton from '../../components/clearbutton';
import DataInput from '../../components/inputs';
import WaButton from '../../components/waboton';
import data from './data.json';
import React from 'react';

const Paellas = () => {


    /*
        {
            "Heading": "Ensaladas",
            "HeadingColor": "#00E676",
            "DishList": [
                {
                    "title": "Ensalada de palmito",
                    "image": "images/platillos/ensalada_palmito.png",
                    "top": 10,
                    "right": 15,
                    "price": 170,
                    "desc": true
                },
                {
                    "title": "Ensalada de camarón",
                    "image": "images/platillos/ensalada_camaron.png",
                    "top": 10,
                    "right": 15,
                    "price": 170,
                    "desc": true
                },
                {
                    "title": "Ensalada de esparragos",
                    "image": "images/platillos/ensalada_esparragos.png",
                    "top": 10,
                    "right": 15,
                    "price": 170,
                    "desc": true
                },
                {
                    "title": "Ensalada de gulas",
                    "image": "images/platillos/ensalada_gulas.png",
                    "top": 10,
                    "right": 15,
                    "price": 170,
                    "desc": true
                },
                {
                    "title": "Ensalada de pollo",
                    "image": "images/platillos/ensalada_pollo.png",
                    "top": 10,
                    "right": 8,
                    "price": 170,
                    "desc": true
                }
            ]
        },
        */


    const showcaseFondo = 'images/showcases/pedido.jpg';
    return (
        <>
            <Showcase
                m1={'¡Ven por ella!'}
                m3={'Prepara tu orden, envíala, espera la confirmación y pasa a recogerla'}
                fondo={showcaseFondo}
            />
            <GridContainer>
                <LeftContainer>
                    <YellowSub>Nuestros platillos:</YellowSub>
                    <HLine />
                    <Platillos
                        data={data}
                        showButton={true}
                    />
                </LeftContainer>
                <RightContainer>
                    <YellowSub>Tu orden:</YellowSub>
                    <OrderContainer>
                        <Order />
                    </OrderContainer>
                    <Total />
                    <ClearButton />
                    <DataInput />
                    <WaButton />
                </RightContainer>
            </GridContainer>
        </>
    );
};

export default Paellas;
