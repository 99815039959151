import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
`

export const TapasContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 10px 0px;
`

export const HLine = styled.hr`
    margin-top: 1px;
    border-top: 2px solid #ff0000;
    color: transparent;
`

export const TextContainer = styled.div`
    margin: 10px 10px 20px 10px;
`

export const YellowSub = styled.h2`
	color: #0000CD;
    font-weight: 600;
    font-family: 'Caveat', cursive;
`

export const TextList = styled.div`
    @media screen and (max-width: 550px) {
        max-width: 232px;
    }
`

export const PhoneNumber = styled.p`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #ff0000;
`

export const Uds = styled.p`
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    font-family: 'Caveat',cursive;
    color: #0000CD;
`

export const Charola = styled.img`
    height: 260px;
    margin: 2px;
    object-fit: contain; 
`

export const Extra = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 8px 0px;
`

export const ExtraItem = styled.div`
    font-family: "Montserrat", sans-serif;
    color: black;
    font-size: 40px;
    font-weight: 600;
    font-family: 'Caveat',cursive;
`

export const ExtraPrice = styled.div`
    font-family: 'Caveat',cursive;
    color: black;
    font-size: 40px;
    font-weight: 600;
    background-color: #ffed00;
    border-radius: 50%;
    padding: 4px;
    margin-left: 12px;
`

// Top Section
export const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 25px 5px;
`

export const Img = styled.img`
    width: 150px;
    object-fit: contain; 
`

export const InfoContainer = styled.div`
    position: absolute;
    bottom: -20px;
    width: 100%;
`

export const Price = styled.div`
    position: absolute;
    background-color: #ffd500;
    top: -20px;
    left: 38px;
    height: 30px;
    width: 70px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-top: 8px;
`

export const Title = styled.h1`
    font-family: 'Caveat',cursive;
    color: #0000CD;
    font-size: 25px;
    font-weight: bold;
    text-align: Center;
`

export const List = styled.div`
    font-size: 17px;
    font-weight: bold;
    max-width: 200px;
`

export const Paella = styled.div`
    position: relative;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px 10px;
`

export const PaellaDesc = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    padding: 5px 20px;
    justify-content: center;
`
export const PaellaTitle = styled.h1`
    font-family: 'Caveat',cursive;
    color: #ff0000;
    font-size: 32px;
    font-weight: bold;
    text-align: Left;
    line-height: 44px;
`
export const PaellaPrice = styled.div`
    position: absolute;
    background-color: #ffd500;
    top: 0px;
    left: 0px;
    height: 30px;
    width: 70px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-top: 8px;
`
