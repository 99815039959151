import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 2px solid #ff0000;
    padding: 0px 12px;
`

export const LinkContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
`

export const NavRoute = styled(NavLink)`
    text-decoration: none;
    z-index: 2;
`

/*
export const NavIcon = styled.i`
    position: absolute;
    top: -9px;
    font-size: 55px;
    color: ${props => props.color};
    opacity: 0.4;
`
*/

export const NavElement = styled.div`
    padding: 2px 5px;
    text-align: center;
    max-width: 90px;
    min-width: 70px;
`

export const NavText = styled.p`
    font-family: 'Caveat',cursive;
    color: ${props => props.color};
    font-size: 23px;
    font-weight: bold;
    line-height: 22px;
`

export const PromoExpress = styled.img`
    position: absolute;
    height: 38px;
    top: 48px;
    left: -15px;
    z-index: 1;
`

export const PromoCongelado = styled.img`
    position: absolute;
    height: 38px;
    top: 38px;
    left: -15px;
    z-index: 1;
`

export const Modal = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;

    display: flex;
    align-items: center;
    flex-direction: column;

    height: 100vh;
    width: 100vw;
    z-index: 9;
    overflow-y: scroll;
`

export const ModalBackground = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.8;
    z-index: -9;
`

export const ModalImage = styled.img`
    max-width: 90vw;
    width: 1080px;
    padding: 2px 0px;
`

export const CloseModal = styled.div`
    position: fixed;
    right: 25px;
    top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #dab600;
    cursor: pointer;
    background-color: black;
    opacity: 0.7;
`
