import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: .5rem;
    border: 2px ${props => props.color} solid;
    border-radius: 4px;
    max-width: 232px;
`

export const DishImg = styled.img`
    padding-top: 0.5rem;
    width: 200px;
`

export const QtyContainer = styled.div`
    position: absolute;
    transform: rotate(-45deg);
    top: 14px;
    left: -24px;
    border-bottom: 24px solid ${props => props.color};
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    width:50px;
`

export const Qty = styled.div`
    position: absolute;
    width: 100%;
    font-family: 'Caveat', cursive;
    font-weight:bold;
    font-size: 18px;
    text-align: center;
    color: white;
`

export const Titulo = styled.h2`
    font-family: 'Caveat', cursive;
    font-size: 22px;
    text-align: center;
    line-height: 1;
`

export const Desc = styled.h2`
    font-family: 'Caveat', cursive;
    font-size: 20px;
    text-align: center;
    line-height: 1;
`

export const PriceContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
`

export const Price = styled.div`
    position: absolute;
    background-color: #ffd500;
    top: ${props => props.top}px;
    right: ${props => props.right}px;
    height: 46px;
    width: 80px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    padding-top: 14px;
`

export const AddButton = styled.button`
    font-size: 10px;
    font-weight: bold;
    color: white;
    padding: 0px 15px;
    margin-left: 0.5rem;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    cursor: pointer;
`
