import OrderItem from '../orderItem';
import { connect } from 'react-redux';
import { DiscTxt } from './style.js';
import React from 'react';

const Order = ({ order }) => {
    const discountTxt = "**De Lunes a Miércoles, 3x2 en todo el menú. Te invitamos el de menor precio.";
        
    const renderOrden = () => 
        order.map(dish => 
            <div key={dish.title}>
                <OrderItem
                    title={dish.title}
                    desc={dish.desc}
                    price={dish.price}
                    count={dish.count}
                />
            </div>
       );

    return (
        <div>
            {renderOrden()}
            <DiscTxt>{discountTxt}</DiscTxt>
        </div>
    );
};

const mapStateToProps = state => {
    return ({ order: state.order });
};

export default connect(mapStateToProps, {})(Order);
