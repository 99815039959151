import {
    DishesContainer,
    PlatoContainer,
    Heading,
    ExpandIcon
} from './style';
import Plato from '../plato';
import React from 'react';

const Platillos = ({ data, showButton }) => {

    function toggleDishList(section) {
        const dishlist = document.getElementById(section+" DishList");
        const icon = document.getElementById(section+" Icon");
        if (dishlist.classList.contains("d-none")) {
           dishlist.classList.remove("d-none") 
           icon.innerHTML = "&#9650;"

        } else {
           dishlist.classList.add("d-none") 
           icon.innerHTML = "&#9660;"
        }
    }

    const renderList = () => {
        return data.dishes.map(section => 
            <div key={section.Heading}>
                <Heading onClick={() => toggleDishList(section.Heading)}
                    color={section.HeadingColor}
                >
                    {section.Heading}
                <ExpandIcon id={section.Heading + " Icon"}>
                    &#9650;
                </ExpandIcon>
                </Heading>
                <PlatoContainer id={section.Heading + " DishList"}>
                    <Plato
                        dishList={section.DishList}
                        showButton={showButton}
                        color={section.HeadingColor}
                    />
                </PlatoContainer>
            </div>
        );
    };

    return (
        <DishesContainer>
            {renderList()}
        </DishesContainer>
    );
};

export default Platillos;
