import styled from 'styled-components';

export const GridContainer = styled.div`
    display: flex;
    justify-content: space-between;
    grid-gap: .5rem;
    padding: .5rem;
    min-height: 644px;
`

export const LeftContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

export const HLine = styled.hr`
    margin-top: 1px;
    border-top: 2px solid #ff0000;
    color: transparent;
`

export const RightContainer = styled.div`
    position: sticky;
    top: .5rem;
    height: 50vh;
    display: grid;
    grid-template-rows: auto;
    grid-gap: .5rem;
`

export const OrderContainer = styled.div`
    overflow: scroll;
    height: 40vh;
    padding: .5rem;
    background-color: white;
    border-radius: 4px;
    border: 2px #ff0000 solid;
`

	//color: #f8ed62;
	//color: #03396c;
export const YellowSub = styled.h2`
	color: #0000CD;
    font-weight: 600;
    font-family: 'Caveat', cursive;
`

