import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
`

export const AppContainer = styled.div`
    width: 900px;
`
