import styled from 'styled-components';

export const SendButton = styled.a`
    background-color: #00E676;
    height: 2rem;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    border-radius: 4px;
`
export const DisabledButton = styled.p`
    background-color: #ff0000;
    height: 2rem;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
`

