import {
    Nav,
    LinkContainer,
    NavRoute,
    NavText,
    NavElement,
    PromoExpress,
    Modal,
    ModalBackground,
    ModalImage,
    CloseModal
} from './style';
import LazyLoad from 'react-lazy-load';
import React, { useState } from 'react';

const Navbar = () => {
    const [cartaShown, setCartaShown] = useState(false);

    const promo = 'images/misc/3x2.png';

    function Carta() {

      if (!cartaShown) {
        return null;
      }

      return (
          <Modal>

              <CloseModal onClick={() => setCartaShown(false)}>
                  Volver
              </CloseModal>

              <LazyLoad>
                  <ModalImage src={'images/carta/carta_2025_1.jpg'} alt="Carta"/>
              </LazyLoad>

              <LazyLoad>
                  <ModalImage src={'images/carta/carta_2025_2.jpg'} alt="Carta"/>
              </LazyLoad>

              <ModalBackground></ModalBackground>

          </Modal>
      )
    }

    return (
        <Nav>
            <LinkContainer>
                <NavRoute
                    to="/menu_express"
                    exact
                    activeStyle={{
                        'borderLeft': '2px solid #0000CD',
                        'borderBottom': '2px solid #0000CD',
                    }}
                >
                    <NavElement>
                        <NavText color={"#0000CD"}>Menú Express</NavText>
                    </NavElement>
                </NavRoute>
                { new Date().getMonth() !== 11 ? <PromoExpress src={promo} alt="Promo"/>: ''}
            </LinkContainer>
            <LinkContainer>
                <NavRoute
                    to="/eventos"
                    exact
                    activeStyle={{
                        'borderBottom': '2px solid #ff0000',
                    }}
                >
                    <NavElement>
                        <NavText color={"#ff0000"}>
                            Eventos
                        </NavText>
                    </NavElement>
                </NavRoute>
            </LinkContainer>
            <LinkContainer>
                <NavElement onClick={() => setCartaShown(true)}>
                    <NavText color={"#00E676"}>
                        Carta
                    </NavText>
                </NavElement>
            </LinkContainer>

            <Carta></Carta>
        </Nav>
    );
};

export default Navbar;
