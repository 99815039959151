import {
    FooterContainer,
    IconsContainer,
    DatosContainer,
    IconLink,
    InfoContainer,
    InfoP,
    BlueSub,
    HomeRoute
} from './style';
import {
    FaFacebook,
    FaInstagram,
} from 'react-icons/fa';
import React from 'react';

const Footer = () => {
    return (
        <FooterContainer>
            <InfoContainer>
                <DatosContainer>
                    <div>
                        <HomeRoute
                            to="/"
                        >
                            Inicio
                        </HomeRoute>
                        <InfoP>
                            <BlueSub>Dirección:</BlueSub>
                            <br/>
                            Bosques de Canadá 108-Local 3, Bosques del Valle 1er Sector, 66220 Monterrey, N.L.<br/>
                        </InfoP>
                    </div>
                    <div>
                        <InfoP>
                            <BlueSub>Teléfonos:</BlueSub>
                            <br />
                            8183786702 o 8183352660
                            <br />
                            <BlueSub>Redes:</BlueSub>
                        </InfoP>
                        <IconsContainer>
                            <IconLink
                                href="https://www.facebook.com/laspaellasdefermin/"
                                target="_blank"
                                aria-label="Facebook"
                            >
                                <FaFacebook />
                            </IconLink>
                            <IconLink
                                href="https://instagram.com/laspaellasdefermin?igshid=o4nxueaxybox"
                                target="_blank"
                                aria-label="Instagram"
                            >
                                <FaInstagram />
                            </IconLink>
                        </IconsContainer>
                    </div>
                </DatosContainer>
            </InfoContainer>
        </FooterContainer>
    );
};

export default Footer;
