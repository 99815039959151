import {
    Container,
    TapasContainer,
    TextContainer,
    PhoneNumber,
    Charola,
    Extra,
    ExtraItem,
    ExtraPrice,
    Price,
    Item,
    Img,
    InfoContainer,
    Title,
    List,
    Paella,
    PaellaDesc,
    PaellaTitle,
    PaellaPrice
} from './style';
import Showcase from '../../components/showcase';
import LazyLoad from 'react-lazy-load';
import data from './data.json';
import React from 'react';

const CharolasBotaneras = () => {

    const showcaseFondo = 'images/showcases/charolas.jpg';

    return (
        <>
            <Showcase
                fondo={showcaseFondo}
                m1={'Haga su selección y llamenos'}
            />

            <Container>

                <TapasContainer>
                    {data.items.map((item, index) => {
                        return (
                            <Item key={index}>
                                <LazyLoad key={index}>
                                    <Img src={item.img}/>
                                </LazyLoad>

                                <Price>{item.price}</Price>
                                <InfoContainer>
                                    <Title>{item.title}</Title>
                                </InfoContainer>
                            </Item>
                        )
                    })}
                </TapasContainer>

                <TapasContainer>
                    {data.paellas.map((item, index) => {
                        return (
                            <Paella key={index}>
                                <PaellaPrice>{item.price}</PaellaPrice>
                                <LazyLoad>
                                    <Img src={item.img}/>
                                </LazyLoad>
                                <PaellaDesc>
                                    <PaellaTitle>{item.title}</PaellaTitle>
                                    <List>{item.list}</List>
                                </PaellaDesc>
                            </Paella>
                        )
                    })}
                </TapasContainer>

                <Charola src={'images/charolas/botaneras1.jpg'}/>

                <TextContainer>
                    <Extra>
                        <ExtraItem>12 unidades</ExtraItem>
                        <ExtraPrice>$300</ExtraPrice>
                    </Extra>
                    <Extra>
                        <ExtraItem>24 unidades</ExtraItem>
                        <ExtraPrice>$600</ExtraPrice>
                    </Extra>
                    <Extra>
                        <ExtraItem>48 unidades</ExtraItem>
                        <ExtraPrice>$1200</ExtraPrice>
                    </Extra>
                    <PhoneNumber>83-78-67-02</PhoneNumber>
                    <PhoneNumber>83-35-26-60</PhoneNumber>
                </TextContainer>

            </Container>
        </>
    );
};

export default CharolasBotaneras;
