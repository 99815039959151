import styled from 'styled-components';

export const YellowSub = styled.h3`
    font-size: 14px;
    font-weight: bold;
	color: #03396c;
`


export const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

export const TotalContainer = styled.div`
    display: flex;
    flex-direction: column;
`
